import { Injectable } from '@angular/core';
import { CoreService } from './core.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private core: CoreService;
  public initCore = (core: CoreService) => this.core = core;

  constructor(private http: HttpClient) {
  }

  // AUTH
  login = data => this.genericQuery('post', 'auth/login', false, data);
  me = () => this.genericQuery('get', 'auth/me', true);
  logout = () => this.genericQuery('get', 'auth/logout', true);
  recoverPass = (phone: string) => this.genericQuery('post', 'auth/recovery-password', true, { phone: phone });
  changePass = data => this.genericQuery('post', 'auth/recovery-hash', true, data);
  getServices = (page=1) => this.genericQuery('get','service/fetch?page='+page, true);
  putService = data => this.genericQuery('post', 'service/put', true, data);
  deleteService = (uuid) => this.genericQuery('delete', 'service/'+uuid+'/delete', true);

  public genericQuery(method:string, uri:string, auth=false, data:any=null, options:any={}): Observable<any> {
    if (auth) {
      if (options['headers'] == undefined) options['headers'] = {};
      options.headers.Authorization = this.core.auth.token;
    }

    if (!this.core.env.endpoint.endsWith('/')) this.core.env.endpoint += "/";

    if (data) {
      return this.http[method.toLowerCase()](this.core.env.endpoint + uri, data, options);
    } else {
      return this.http[method.toLowerCase()](this.core.env.endpoint + uri, options);
    }
  }

}
