import { environment } from './../../environments/environment';
import { Injectable, ApplicationRef } from '@angular/core';
import { ApiService } from './api.service';
import { ToastController, LoadingController, NavController, AlertController, ModalController } from '@ionic/angular';
import { AuthService } from './auth/auth.service';
import { first } from 'rxjs/internal/operators/first';
import { VersionService } from './version.service';

@Injectable({
    providedIn: 'root'
})
export class CoreService {

    public env: any = environment;
    public get isLoggedIn() { return this.auth.token != ''; };
    public get inited() { return this.auth.inited; }

    public stable = false;
    public appIsStable = this.appRef.isStable.pipe(first(isStable => {
        if (isStable === true) {
            this.stable = true;
            return true;
        } else {
            return false;
        }
    }));

    constructor(
        public api: ApiService,
        public auth: AuthService,
        public version: VersionService,
        public loadingCtrl: LoadingController,
        public modalCtrl: ModalController,
        public toastCtrl: ToastController,
        public navCtrl: NavController,
        public alertCtrl: AlertController,
        private appRef: ApplicationRef,
    ) {
        if (!this.env.endpoint.endsWith('/')) this.env.endpoint += '/';

        api.initCore(this);
        auth.initCore(this);
        version.initCore(this);

        if (!this.env.production) console.log('Loaded env: ', this.env);
    }

    public async createLoading(text = null, noPresent = false) {
        let loading = await this.loadingCtrl.create({ message: text });
        if (!noPresent) await loading.present();
        return loading;
    }

    public async successToast(loading = null, message = null, duration = null) {
        if (loading) loading.dismiss();
        await (await this.toastCtrl.create({
            message: message || 'Acción completada correctamente',
            duration: duration || 3000,
            color: 'success',
            buttons: [{ text: 'OK', role: 'cancel' }]
        })).present();
    }

    public async errorToast(loading = null, message: any | string = null, duration = null) {
        if (loading) loading.dismiss();

        if (typeof message != 'string' && message != null) {
            if (message.status && message.status == 401 && this.isLoggedIn) {
                this.auth.logout(() => this.navCtrl.navigateRoot('/login?msg=expired'));
            }
            message = this.fetchErrMsg(message.error);
        }

        return await (await this.toastCtrl.create({
            message: message || 'Se ha producido un error, por favor inténtelo de nuevo más tarde',
            duration: duration || 5000,
            color: 'danger',
            buttons: [{ text: 'OK', role: 'cancel' }]
        })).present();
    }

    public fetchErrMsg(err) {
        if (err.error) return err.error;
        if (err.errors) {
            let msg = 'Se han producido los siguientes errores: ';
            for (let itm in err.errors) {
                msg += '\n- ' + err.errors[itm].toString();
            }
            return msg;
        }
        if (Object.getPrototypeOf(err).toString().indexOf('ProgressEvent')) return 'Se ha producido un error de comunicación, por favor compruebe su conexión';
    }

    public getPatchOrImage(ref: any, thumb: number = null) {
        if (ref.patch_picture && ref.patch_picture != 'DELETE') {
            return ref.patch_picture;
        }
        if (ref.picture) {
            return this.env.endpoint + 'resource/' + ref.picture + (thumb ? ('?width=' + thumb) : '');
        }
        return null;
    }

    public resize(textarea = null) {
        if (textarea) textarea.getInputElement().then(element => {
            let scrollHeight = element.scrollHeight;
            element.style.height = scrollHeight + 'px';
            element.parentElement.style.height = (scrollHeight + 16) + 'px';
        });
    }

}
