import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/providers/core.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  public data = {
    email: '',
    password: ''
  };

  constructor(public core: CoreService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    let msg = this.activatedRoute.snapshot.queryParams.msg;
    if (msg) {
      if (msg==='accountactivated') {
        this.core.successToast(null, 'Su cuenta ha sido activada correctamente. Ya puede iniciar sesión', 30000);
      }
      if (msg==='logout') {
        this.core.successToast(null, 'Has cerrado sesión. ¡Nos vemos pronto!', 10000);
      }
      if (msg==='expired') {
        this.core.errorToast(null, 'Su sesión ha sido cerrada por seguridad. Por favor, vuelva a iniciar sesión.', 10000);
      }
      this.core.navCtrl.navigateRoot('/login');
    }
  }

  login() {
    this.core.createLoading().then(loading => {
      this.core.auth.login(this.data, () => {
        loading.dismiss();
        this.core.navCtrl.navigateRoot('/home');
      }, err => this.core.errorToast(loading, err, 3000));
    });
  }

}
