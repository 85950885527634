import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { CoreService } from './providers/core.service';
import { menu, MenuInterface } from './providers/config/menu';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  public appPages: MenuInterface[] = menu;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    public core: CoreService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      let func = () => {
        if (this.core.inited) {
          this.splashScreen.hide();
        } else setTimeout(func, 1000);
      };
    });
  }

  menuEntryAccess(p: MenuInterface): boolean {
    let can = true;
    if (can && p.requireLogin) can = this.core.isLoggedIn;
    if (can && p.requireAdmin) can = this.core.auth.hasRole('ROLE:PROGRAMMER');
    return (can)?true:false;
  }

  logout = () => this.core.auth.logout(() => this.core.navCtrl.navigateRoot('/login?msg=logout'));

}
