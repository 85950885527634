export interface MenuInterface {
    title: string;
    url: string;
    icon: string;
    isTitle: boolean;
    requireLogin: boolean;
    requireAdmin: boolean;
}

export const menu: MenuInterface[] = [
    {
        title: 'Inicio',
        url: '/home',
        icon: 'home',
        isTitle: false,
        requireLogin: true,
        requireAdmin: false,
    },
    {
        title: 'Trabajos',
        url: '',
        icon: '',
        isTitle: true,
        requireLogin: true,
        requireAdmin: false,
    },
    {
        title: 'Servicios',
        url: '/project/service',
        icon: 'briefcase',
        isTitle: false,
        requireLogin: true,
        requireAdmin: false,
    },
    {
        title: 'Clientes',
        url: '',
        icon: '',
        isTitle: true,
        requireLogin: true,
        requireAdmin: false,
    },
    {
        title: 'Registro clientes',
        url: '/user/useredit',
        icon: 'person-add',
        isTitle: false,
        requireLogin: true,
        requireAdmin: false,
    },
    {
        title: 'Lista clientes',
        url: '/user/userlist',
        icon: 'people',
        isTitle: false,
        requireLogin: true,
        requireAdmin: false,
    },
    {
        title: 'Cuenta',
        url: '',
        icon: '',
        isTitle: true,
        requireLogin: false,
        requireAdmin: false,
    },
    {
        title: 'Acerca de',
        url: '/About',
        icon: 'build',
        isTitle: false,
        requireLogin: false,
        requireAdmin: false,
    },
];