import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginPage } from './pages/login/login.page';
import { RecoverPage } from './pages/recover/recover.page';
import { HomePage } from './pages/home/home.page';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginPage },
  { path: 'home', component: HomePage},
  { path: 'recover', component: RecoverPage },
  {
    path: 'project',
    loadChildren: () => import('./pages/service-module/service.module').then( m => m.ServiceModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/crm-module/crm-module.module').then( m => m.CrmModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
