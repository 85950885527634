import { Component } from '@angular/core';
import { menu, MenuInterface } from '../../providers/config/menu'

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage {

  public menuitems: MenuInterface[] = menu;

}
